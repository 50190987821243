


































































import { Component, Vue } from "vue-property-decorator";
import { LoginViewModel } from "../../models/LoginViewModel";
import AuthService from "../../services/AuthService";
import AuthUser from "../../../global-config/AuthUser";
import AlertService from "../../../common-app/service/AlertService";

@Component({
  components: {},
})
export default class Login extends Vue {
  public submitted: boolean = false;
  public user: LoginViewModel = {} as LoginViewModel;
  // GetData() {
  //   AuthService.test().then((response) => console.log(response));
  // }
  created(){
    const dict = {
      custom: {
        phone: {
          required: "The phone or email is required"
        },
        password: {
          required: "The password is required"
        }
      }
    };
    this.$validator.localize("en", dict);
  }
  onSubmit() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.submitted = true;
        AuthService.login(this.user)
          .then((response) => {
            if (response.data.isAuthorized) {
              AuthUser.setToken(response.data);
              this.$router.push({ path: "user" });
            } else {
              this.submitted = false;
              AlertService.sendMessage(response.data);
            }
          })
          .catch((error) => {
            this.submitted = false;
          });
      }
    });
    
  }
}
